.address-overlay-shipping,
.address-overlay-billing {
  fieldset {
    .address-form__header {
      margin-left: 0px;
    }
    .address-form__content {
      .form_element {
        margin-bottom: 6px;
        input,
        select {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .title_container {
        margin-top: 6px;
      }
    }
  }
}

.newsletter-info {
  .sms_promotions {
    margin: 0 0 20px 20px;
  }
}

@media (min-width: 768px) {
  .site-nav {
    .menu__item--lvl-1 {
      vertical-align: top;
    }
  }
}

.sterling-nights {
  .product-collection-product__price {
    width: auto;
  }
  .product_smokey-mascara {
    .product-collection-product__price {
      width: auto;
    }
  }
}

// Accessibility
.INDhighlightLinks,
.INDhighlightAll {
  & .site-footer__main a {
    line-height: 19px;
  }
  &.INDblackwhite .continue-buttons a {
    color: #fff !important;
  }
  .continue-buttons a {
    color: #000 !important;
  }
}

.sticky-back-to-top__text {
  display: none;
}

.sticky-back-to-top__icon {
  right: 0;
  top: 3px;
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: block;
      }
    }
  }
}

.homepage-slide {
  .block-template-hp-module-slide-v2 & {
    .slick-next {
      @include breakpoint($medium-up) {
        right: 15px;
      }
    }
    .slick-prev {
      @include breakpoint($medium-up) {
        left: 15px;
      }
    }
  }
  .slick-next {
    @include breakpoint($medium-up) {
      right: auto;
    }
  }
}

.spp-product-layout__content-item {
  .spp-cross-sells {
    .product-grid--carousel {
      .product-grid__content {
        & > .slick-next,
        & > .slick-prev {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.product-grid-v2 {
  button {
    &.slick-next {
      #{$ldirection}: 96%;
      &:before {
        #{$ldirection}: 0;
        transform: rotate(180deg);
      }
    }
    &.slick-prev {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
}

.section-esearch {
  #perlgem-search-form {
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
    .search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
  #search-wrapper {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          margin-#{$rdirection}: 0;
        }
        .product-grid__content {
          .product-brief__shades {
            .product-brief-shades__grid {
              .slick-prev {
                #{$rdirection}: auto;
                #{$ldirection}: 0;
              }
              .shade-inventory-status {
                &--4:after,
                &--7:after {
                  border-#{$ldirection}: none;
                  #{$ldirection}: -5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-grid {
  &.product-grid--carousel {
    .carousel-controls {
      .slick-next,
      .slick-prev {
        transform: rotate(180deg);
      }
    }
  }
}

.foundation-finder-result {
  &__sections {
    .slick-slider {
      .slick-prev {
        transform: rotate(180deg);
        #{$ldirection}: 0;
        #{$rdirection}: auto;
        &::before {
          #{$rdirection}: auto;
        }
      }
      .slick-next {
        transform: rotate(180deg);
        #{$rdirection}: 0;
        #{$ldirection}: auto;
        &::before {
          #{$ldirection}: auto;
        }
      }
    }
  }
}

.videojs-video {
  &__landing {
    &__image {
      img {
        transform: translateX(50%);
      }
    }
  }
}
