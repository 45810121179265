.enhanced-delivery-page {
  .pg_wrapper {
    #continue-btn {
      float: $rdirection;
    }
    .shipping-panel {
      .gift_container,
      .shipping_container {
        dl {
          margin: 0;
        }
      }
    }
    .column.right {
      .links-panel {
        li {
          display: block;
          margin-bottom: 10px;
          padding-bottom: 0;
        }
      }
      .offer-code-panel {
        .error_messages {
          li {
            &[id*='offer_deferred'] {
              color: $color-green-light;
            }
          }
        }
      }
    }
    .column.left {
      @include box-shadow(none);
      .wrapper {
        .checkout__panel-title-container {
          .shipping-panel__title {
            display: none;
          }
        }
        #billing_address_form_container {
          .country_container {
            padding: 18px 15px;
            margin-top: 22px;
          }
        }
        .billing-address-form-container {
          .form_element.static-country {
            border: 1px solid;
          }
        }
        #checkout_shipping {
          #emailpromo-info-panel {
            .newsletter-info {
              .news-letter-icon {
                #{$ldirection}: 95%;
              }
              .newsletter-toggle {
                .sms_promotions {
                  .sms_promo_container {
                    .phone_container {
                      &.form_element {
                        padding: 22px 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
          #shipping-address-info-panel {
            #shipping-info-panel.new-form-expand {
              #choose-address {
                border-bottom: 0;
              }
              .shipping-address-form-container {
                border-bottom: 1px solid $color-black-2;
                margin-bottom: 0;
                padding: 0 0 25px;
              }
              .address_controls {
                #choose-address {
                  .add-new-address {
                    .add-address {
                      margin-#{$ldirection}: 14px;
                    }
                  }
                }
              }
            }
          }
          .edit-billing-address-form-container {
            .delivery-instructons-container {
              display: none;
            }
          }
          #billing-address-info-panel {
            margin-bottom: 25px;
            .billing-info-panel__title {
              margin-top: 20px;
            }
            .address_controls {
              li .edit-billing-address {
                top: 10px;
              }
            }
          }
          .form_element {
            textarea.field {
              @include swap_direction(padding, 17px 20px 13px 15px);
              resize: none;
            }
          }
          .ship-method-container {
            & ~ #billing-address-info-panel {
              .address_controls {
                li .edit-billing-address {
                  top: 10px;
                }
              }
            }
          }
          .shipping-address-info {
            .edit-address-container {
              border-bottom: 0;
            }
          }
        }
        .giftwrap-info {
          .giftwrap-panel__title {
            margin: 13px 0;
          }
          #gift-options-wrapper.gift-wrap {
            padding-top: 0;
            .gift-wrap {
              &__text-yes,
              &__text-no {
                margin-#{$ldirection}: 16px;
                text-transform: uppercase;
                top: 0;
              }
              &-switch,
              &__image {
                float: $ldirection;
              }
              &__image {
                @include swap_direction(margin, 0 24px 10px 0);
              }
              &__title {
                display: inline-block;
                margin: 5px 0 16px;
              }
            }
            .gift-options-container {
              float: $rdirection;
              width: 78%;
              .giftmsg_message_container {
                .message-field {
                  margin-bottom: 6px;
                  resize: none;
                }
              }
            }
          }
        }
        #payment-info-panel {
          #payment-type-cc {
            .payment-type {
              &--static {
                img {
                  width: auto !important;
                }
              }
            }
          }
        }
        #accept_terms {
          position: relative;
          input[type='checkbox'] {
            #{$ldirection}: 0;
          }
        }
        #create_account_form_container {
          .field:read-only {
            background: $color-light-brown;
          }
        }
      }
      #checkout_complete {
        .wrapper {
          #payment-info-panel {
            #payment-type-cc,
            #payment-type-ncc {
              border-color: $color-nobel;
              .payment-type {
                border: 0;
                .payment_option_label {
                  font-size: 12px;
                  line-height: 1.67;
                  img {
                    margin-top: -2px;
                  }
                }
              }
            }
          }
        }
      }
    }
    #delivery-options .ship-method-group-label {
      .delivery_option {
        height: 96px;
        padding-#{$ldirection}: 0;
        position: relative;
        text-align: center;
        width: 269px;
        line-height: 1.2;
        padding-top: 55px;
        &::after {
          #{$rdirection}: 46%;
          bottom: 50px;
          height: 24px;
          position: absolute;
          width: 24px;
        }
        input[type='radio'] {
          #{$ldirection}: auto;
          #{$rdirection}: auto;
          position: absolute;
          &::after {
            bottom: 20px;
          }
        }
      }
      .click_and_collect_option {
        margin-#{$rdirection}: 0;
      }
    }
    #billing_address_form_container {
      .delivery_instructions_container {
        display: none;
      }
      .country_container {
        background: $color-light-brown;
        border: 1px solid $color-black;
        color: $color-black;
        height: 54px;
        margin-top: 22px;
        padding: 18px 15px;
        &::after {
          display: none;
        }
      }
    }
  }
  &#payment {
    .column.right {
      &.checkout__sidebar {
        #shipping-panel {
          .transaction-panel-details {
            .transaction-item.gift_container {
              display: none;
            }
          }
        }
      }
    }
  }
}
