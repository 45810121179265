.checkout {
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
      background: #eff0f0;
      @include swap_direction(padding, 16px 20px);
      p {
        margin-bottom: 15px;
      }
    }
    .account-utilities__section {
      h3.account-utilities__header {
        font-family: 'Brandon Text Bold';
        @include swap_direction(margin, 4px 0 10px 0);
        padding-bottom: 17px;
        border-bottom: 1px solid #000000;
        text-transform: uppercase;
      }
    }
    #links-panel {
      background-color: #fff;
      border: 4px solid #eff0f0;
      .checkout-co-enabled & {
        background-color: transparent;
        border: none;
      }
      ul.links_list {
        li {
          border: 0px;
          margin-bottom: 10px;
        }
      }
    }
    #offer-code-panel {
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_form_container {
          #one-offer-only {
            display: block;
            @include swap_direction(margin, 10px 0px);
          }
        }
      }
    }
  }
  .checkout__content {
    #viewcart-panel {
      .viewcart-panel__content {
        .total_column.sub__total {
          text-align: $rdirection;
          @include swap_direction(margin, 10px 0px);
        }
      }
    }
    #shipping-panel {
      .transaction-panel-details {
        margin-top: 20px;
        .transaction-item {
          float: $ldirection;
          @include swap_direction(margin, 0 28px 28px 0);
          position: relative;
          width: 275px;
          word-wrap: break-word;
        }
      }
    }
    #review-instructions {
      @include swap_direction(padding, 15px 0px);
      p {
        @include swap_direction(margin, 0px);
      }
    }
  }
  .below-columns {
    #recommended-products-panel {
      .recommended-products-panel__content .recommended-product-items .recommended-item {
        .description {
          @include swap_direction(margin, 10px 0px);
          .product_name {
            min-height: inherit;
          }
          div {
            @include swap_direction(margin, 0px 0px 10px 0px);
          }
          .color .swatch {
            margin-#{$rdirection}: 10px;
          }
        }
      }
    }
  }
  ol.checkout-progress li {
    width: 175px;
  }
}

#samples-panel #checkout_samples {
  .samples-buttons.bottom {
    display: block;
  }
  .samples {
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: #000000;
      }
    }
  }
}

.shipping {
  .checkout-progress li.checkout-progress__shipping {
    font-weight: bold;
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      section {
        margin-bottom: 10px;
      }
      #shipping-address-info-panel {
        #address_form_container .address {
          .address-form__content {
            .title_container {
              margin-bottom: 10px;
            }
          }
        }
        #shipping-info-panel {
          padding: 0px;
          .shipping-address {
            margin-bottom: 10px;
          }
          ul.address_controls li {
            margin-bottom: 10px;
            &#choose-address select {
              margin-top: 5px;
            }
          }
        }
      }
      #billing-address-info-panel #billing-info-panel {
        padding: 0px;
        .billing-address {
          margin-bottom: 10px;
        }
      }
      #delivery-info-panel #delivery-info-content-panel {
        @include swap_direction(padding, 0px);
        #delivery-options {
          p {
            margin-bottom: 10px;
          }
        }
      }
      #emailpromo-info-panel .newsletter-info {
        padding: 0px;
        h3.newsletter-info__header {
          text-indent: 0px;
        }
        p {
          @include swap_direction(margin, 0px 0px 15px 0px);
        }
        .newsletter-info__item {
          margin-#{$ldirection}: 0px;
          padding-#{$ldirection}: 0px;
        }
        .sms_promotions {
          margin-#{$ldirection}: 0px;
        }
      }
    }
  }
}

.review {
  .checkout-progress li.checkout-progress__review {
    font-weight: bold;
  }
  .checkout__content {
    #checkout_review {
      .promotions-container {
        h3.newsletter-info__header {
          text-indent: 0px;
        }
      }
      p {
        @include swap_direction(margin, 0px 0px 15px 0px);
      }
      .newsletter-info__item {
        margin-#{$ldirection}: 0px;
        padding-#{$ldirection}: 0px;
      }
      .sms_promotions {
        margin-#{$ldirection}: 0px;
      }
    }
  }
}

.billing {
  .checkout-progress li.checkout-progress__billing {
    font-weight: bold;
  }
  #checkout_complete {
    #payment-info-panel {
      .payment_method_container {
        margin-bottom: 15px;
        p {
          @include swap_direction(margin, 10px 0px);
        }
        div {
          margin-bottom: 10px;
          &.direct-debit .direct-debit-nickname input {
            margin-bottom: 0px;
          }
        }
        .payment-type {
          label {
            display: block;
            overflow: hidden;
            height: 60px;
            @include swap_direction(padding, 20px 0px);
            img {
              position: absolute;
              margin-top: -7px;
              #{$ldirection}: 30px;
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label {
              img {
                width: 60px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
              img {
                margin-top: -20px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label {
              img {
                margin-top: -20px;
              }
            }
          }
        }
      }
    }
  }
  #promo-message {
    margin-bottom: 15px;
  }
}

.confirm {
  .checkout-progress li.checkout-progress__confirm {
    font-weight: bold;
  }
  .checkout__content {
    #confirm-panel {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .cart-item {
    margin-top: 20px;
  }
  .cart-item__thumb {
    width: 13%;
    margin-#{$rdirection}: 10px;
  }
  .cart-item__desc {
    float: $ldirection;
    width: 30%;
    margin-#{$ldirection}: 10px;
  }
  .cart-item__price {
    text-align: center;
  }
  .cart-item__qty {
    float: $ldirection;
    clear: none;
    padding-top: 0;
    @include swap_direction(margin, 0 10px);
    width: 14%;
    text-align: center;
  }
  .cart-item__total {
    width: 14%;
    float: $ldirection;
    line-height: 36px;
    text-align: $rdirection;
  }
  .order-totals {
    float: $rdirection;
    table {
      th,
      td {
        border: 0px;
      }
    }
  }
}

.section-store-locator.device-pc {
  .resp-tabs-list {
    li:first-child {
      width: 60%;
    }
    li:last-child {
      width: 40%;
    }
  }
  .store-locator__main {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      letter-spacing: 1px;
      @include swap_direction(padding, 1em 5px);
    }
    .customer-service-contact-form__category#category-products {
      letter-spacing: 0px;
      @include swap_direction(padding, 25px 2px 0px 2px);
    }
    .customer-service-contact-form__section {
      #category-online-content {
        .customer-service-contact-form__radio-item label::before {
          font-size: 13px;
          margin-top: 1px;
        }
        .customer-service-contact-form__radio-item label {
          .orderstatus_exchanges_radiobutton {
            display: inline-block;
            margin-top: 1px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.gtl__steps__content {
  .gtl__steps__body p {
    width: 90%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        letter-spacing: 0px;
        @include swap_direction(padding, 1em 5px);
        word-wrap: break-word;
      }
      .customer-service-contact-form__category#category-products {
        letter-spacing: 0px;
        @include swap_direction(padding, 1em 5px);
      }
    }
  }
  .customer-service-menu {
    a.menu__link--lvl-1 {
      width: auto;
    }
  }
  .telluride-collection {
    .lw-gel,
    .text-block {
      height: 430px;
    }
    .smokey-eye {
      .product-collection-product {
        bottom: 0;
      }
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
    .store-locator__link-events {
      float: $rdirection;
      @include swap_direction(margin, 5px 0 0 20px);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section-store-locator {
    .store-locator__form-container {
      #store-search-controls {
        display: block;
      }
    }
    .store-locator__main .responsive-tabs {
      .resp-tabs-list {
        li:first-child {
          width: 64%;
          letter-spacing: 0.05em;
        }
        li:last-child {
          width: 36%;
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

.sign-in.is_signed_in {
  width: 320px;
}

.elc-user-state-anonymous {
  .site-email-sms-signup {
    .site-email-sms-signup__header {
      h1 {
        font-size: 2em;
        letter-spacing: 2px;
      }
      h2 {
        font-size: 1.4em;
        letter-spacing: 0;
      }
    }
    .site-email-sms-signup__agree {
      span.label-content {
        letter-spacing: 0.9px;
      }
      input#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS {
        display: none;
      }
    }
  }
}

.samples-page {
  .product {
    min-height: 482px;
    width: 21.6%;
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.section-esearch {
  #perlgem-search-form {
    .search-form__submit {
      letter-spacing: 0.1em;
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    .registration form input {
      width: 100%;
    }
    .registration__password-container {
      overflow: hidden;
    }
    .registration__show-pass {
      @include swap_direction(margin, 5px 0 0 0);
    }
    .registration__show-pass-text {
      letter-spacing: 0em;
    }
    .registration__terms {
      margin-top: 10px;
    }
    .registration__email-list {
      @include swap_direction(margin, 10px 0 18px 0);
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .site-footer {
    .site-footer-contact {
      .customerservice_width {
        width: auto;
        .site-footer-contact__link {
          margin-#{$ldirection}: 1%;
        }
      }
    }
    .site-email-signup {
      &__field {
        width: 53%;
      }
      &__submit {
        height: 32px;
        letter-spacing: 1px;
        width: 44%;
        @include swap_direction(padding, 0px);
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .site-footer {
    .site-footer-contact {
      .customerservice_width {
        .site-footer-contact__link {
          margin-#{$ldirection}: 10%;
        }
      }
    }
    .site-email-signup {
      .site-email-signup__submit {
        height: 32px;
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .quickshop {
    .product__details .product__actions {
      .product__full-details-link-wrapper {
        float: none;
        margin-bottom: 10px;
      }
      .product__notify-me {
        margin-top: 10px;
      }
    }
  }
}

.site-header {
  .site-utils__promo {
    .basic-carousel-formatter {
      .slick-dots {
        display: none !important;
      }
      .carousel-controls {
        .slick-prev {
          #{$rdirection}: 50px;
          #{$ldirection}: auto;
        }
        .slick-next {
          #{$ldirection}: 50px;
          &:before {
            #{$ldirection}: 0;
          }
        }
        &--color-black {
          .slick-next,
          .slick-prev {
            &::before {
              color: $color-black;
            }
          }
        }
        &--color-white {
          .slick-next,
          .slick-prev {
            &::before {
              color: $color-white;
            }
          }
        }
        &--color-grey {
          .slick-next,
          .slick-prev {
            &::before {
              color: $color-warm-grey;
            }
          }
        }
      }
    }
  }
  .site-utils__item--email {
    .site-email-signup__form {
      .sign-in-component__birthday-program {
        .day-of-birth__collection-field {
          a.select-day {
            width: 25%;
          }
        }
      }
    }
  }
}

.site-header__addtobag {
  .site-header__addtobag-cell {
    .site-header__addtobag__product-info {
      div {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        div {
          font-size: 12px;
        }
      }
    }
    .site-header__addtobag-button {
      a {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        height: 325px;
        .customer-service-landing-block__link {
          bottom: 15px;
        }
      }
    }
  }
  .device-pc {
    .bobbis-lessons {
      &__footer {
        @include swap_direction(margin, 0 2% 0 2%);
      }
    }
    .makeup-lessons-item {
      &__wrap {
        @include swap_direction(padding, 30px 0 25px 0);
      }
    }
    .makeup-lessons-items {
      &__lessons {
        bottom: 5px;
        width: auto;
        .makeup-lessons-item {
          @include swap_direction(margin, 9px 9px 9px 0px);
        }
      }
    }
  }
  .site-nav {
    .menu__item--lvl-2 {
      padding-#{$rdirection}: 25px;
      word-wrap: break-word;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .spp {
    .responsive-tabs--how_to_use-wrap .resp-tab-item {
      @include swap_direction(padding, 8px 15px);
    }
  }
}

.site-footer .site-footer-contact ul {
  li.site-footer-contact__item {
    width: 50%;
    a {
      white-space: pre-wrap;
      span {
        margin-#{$rdirection}: 10px;
      }
    }
  }
}

.ie8 {
  .section-store-locator {
    .store-locator--desktop {
      .store-locator__locate.geo_search,
      .store-locator-either-or {
        display: none !important;
      }
    }
  }
  .sign-in {
    .account-links .account-links__container {
      li.account-links__orders {
        height: auto !important;
      }
    }
  }
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    height: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .past-purchases__content {
    .past-purchases-data-header__item,
    .past-purchases-data-item__item {
      letter-spacing: 0;
    }
    .past-purchases-data-header__item.created-date,
    .past-purchases-data-item__item.created-date {
      width: 20%;
    }
    .past-purchases-data-header__item.trans-id,
    .past-purchases-data-item__item.trans-id {
      width: 31%;
    }
  }
  .offers-hero {
    .offers-hero__actions {
      .offers-hero__button {
        @include swap_direction(margin, 15px 0 0 15px);
      }
    }
  }
  .offers-details {
    .offers-details-item__button {
      @include swap_direction(margin, 13px 0 0 25px);
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .offers-hero {
    .offers-hero__actions {
      .offers-hero__button {
        @include swap_direction(margin, 15px 0 0 15px);
      }
    }
  }
}

.site-utils__menu {
  .site-utils__item--email {
    .site-utils__dropdown--email {
      #{$rdirection}: 100px;
    }
  }
}

.bobbis-lessons__footer {
  a.link--get-the-look {
    padding-#{$rdirection}: 60px;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 50%;
  }
  .bobbis-lessons__footer--right a {
    @include swap_direction(padding, 0px);
  }
}

@media only screen and (max-device-width: 768px) {
  .makeup-lessons__header--content .makeup-lessons__header--link {
    @include swap_direction(padding, 5px 2px);
  }
  a.makeup-lessons__header {
    background-size: 650px 424px;
  }
  div.makeup-lessons__header {
    background-size: 480px 328px;
  }
}

@media only screen and (max-device-width: 768px) {
  .site-footer .site-footer-contact ul {
    li.site-footer-contact__item--phone {
      float: none;
      width: 100%;
      text-align: center;
    }
    li.site-footer-contact__item--email {
      width: 100%;
      text-align: center;
    }
  }
}

.mpp.gift_guide {
  .product-filter {
    width: 900px !important;
  }
}

.lw-collection {
  .button--outline {
    width: 195px !important;
  }
}

.bobbi-glow {
  .product_highlighting-powder {
    min-height: 345px;
    background-position: center 20px;
    @include swap_direction(padding, 350px 30px 30px 0);
    border-top: 1px solid #eff0f0;
    border-bottom: 1px solid #eff0f0;
  }
}

.product-grid__content {
  .product-grid__item {
    .product-thumb {
      .product-thumb__abstract {
        .product-thumb--price {
          .legal_msg {
            float: none;
            letter-spacing: 0.001em;
          }
        }
      }
    }
  }
}

.js-popup-offer-overlay-cbox.popup-offer-cbox {
  .popup-offer {
    .popup-offer__outer-wrap.popup-offer__sign-up {
      padding: 15px;
      h2 {
        margin-bottom: 20px;
      }
      .popup-offer__body {
        p {
          margin: 0 0 25px 0;
        }
      }
      .popup-offer__footer {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

.product-full {
  &__media {
    .product-full-image__main__content {
      .slick-prev {
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: auto;
          #{$rdirection}: 0;
          &:before {
            #{$ldirection}: 10px;
          }
        }
      }
      .slick-next {
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 0;
          &:before {
            #{$ldirection}: 0;
          }
        }
      }
    }
  }
}

.product_collection_hero__content {
  .product-grid__content {
    .product-thumb {
      @include swap_direction(padding, 0 15px 20px);
    }
  }
}

.secure_popup.submit {
  text-align: center;
  padding: 17px 0 0px 0;
  .button-image {
    height: 30px;
    width: 137px;
    background: #ff4661;
    color: #ffffff;
    display: inline-block;
    @include swap_direction(padding, 4px);
    cursor: pointer;
  }
}

#payment {
  #cboxLoadedContent {
    overflow: hidden !important;
  }
}

.secure_message {
  font-family: Brandon Text Regular;
  font-size: 15px;
  @include swap_direction(padding, 6px 15px 0px 15px);
  text-align: center;
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        float: left;
        a {
          margin-top: 30px;
          letter-spacing: 1px;
          font-style: normal;
        }
      }
    }
  }
}

#cboxContent {
  .offers-everyday {
    .offers-everyday__items {
      .offers-everyday__item:last-child {
        width: 29% !important;
      }
      .offers-everyday__item:nth-child(2) {
        width: 34% !important;
      }
      .offers-everyday__item:nth-child(1) {
        width: 37% !important;
      }
      .offers-everyday__item-inner {
        @include swap_direction(padding, 0px);
      }
    }
  }
}

.product-full {
  &__container {
    .product-full__media {
      .fullscreen-enabled {
        .product-full-image__main {
          #{$rdirection}: 50%;
          #{$ldirection}: 11%;
          &__content {
            @include breakpoint($portrait-up) {
              padding: 0;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.product-grid-products {
  .product-thumb--price {
    span.legal_msg {
      font-size: 11px;
    }
  }
}

.skin__foundation__dropdown__select__container {
  .skin__foundation__dropdown__select__copy,
  .skin__foundation__dropdown__button {
    font-size: 16px;
    font-weight: bold;
  }
}

.skin__foundation__menu.skin__foundation__menu--01 {
  width: 71.333% !important;
  #{$ldirection}: -139px !important;
  cursor: context-menu !important;
}

.skin__foundation__menu.skin__foundation__menu--02 {
  display: none !important;
}

.skin__foundation__menu.skin__foundation__menu--03 {
  display: none !important;
}

.site-footer {
  .site-footer-contact {
    ul {
      li.site-footer-contact__item {
        width: 33%;
      }
    }
  }
}

.touch {
  select {
    background-position: left 10px center;
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promo {
      .free_ship {
        height: 75px;
      }
    }
  }
}

.spp-product-layout__content {
  &-item-content {
    .spp-cross-sells {
      &__products {
        .product-brief {
          &__shades {
            .shade-inventory-status--4,
            .shade-inventory-status--7 {
              &:after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }
}

.site-utils__dropdown--email {
  .site-utils__dropdown__arrow {
    left: 78px;
  }
}

.spp-cross-sells {
  .spp-cross-sells__products {
    .product-grid--carousel {
      .product-grid__content {
        & > .slick-next,
        & > .slick-prev {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.mpp-grid {
  &__item {
    .product-brief {
      &__shades {
        .shade-inventory-status--4,
        .shade-inventory-status--7 {
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  .quiz-landing-page-formatter {
    &.entire-quiz-one-page {
      .quiz-landing-page-formatter {
        &__retake-quiz {
          #{$ldirection}: 9%;
        }
      }
    }
  }
}

.spp-product-layout {
  .basic-carousel-formatter {
    .slick-prev {
      left: 0;
      right: auto;
      position: absolute;
    }
  }
}
