.hot-collection {
  .product_collection_hero__content-item {
    .button--disabled {
      background-color: #ccc;
      border: 1px solid #ccc;
    }
    .js-quickshop-container {
      .quickshop {
        .product {
          .product__details {
            .product__actions {
              padding: 5px 0 0 10px;
              .product__button {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .block-template-product-collection-prodcut-p-v1 {
      .content {
        .product-palette-atb {
          .product__button--add-to-bag {
            width: 80%;
          }
        }
      }
    }
  }
}

.greige-collection {
  max-width: 1024px;
  margin: 0 auto;
  .product-collection-tabs .tab-item a.current {
    border-bottom: none;
  }
  .product-collection-tabs .tab-item a {
    background-color: #ffffff;
  }
  .callout-alt {
    .button {
      background-color: #ff4661;
      color: #ffffff;
      width: 205px;
    }
    .button--disabled {
      background-color: #cccccc;
      border: 1px solid #cccccc;
    }
  }
  .product__inventory-status {
    font-family: Brandon Text;
    text-align: $ldirection;
    @include swap_direction(padding, 3px 0);
  }
  .quickshop .product-gallery {
    border-#{$rdirection}: none;
  }
  .quickshop__container {
    background-color: #ffffff;
    @include swap_direction(margin, 0);
  }
  .quickshop-wrapper {
    position: fixed;
    z-index: 100;
    width: 768px;
    max-height: 77%;
    overflow: auto !important;
    top: 12.5%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @include swap_direction(margin, 5% auto);
    &.active::before {
      display: none;
    }
    .wrap {
      @include swap_direction(padding, 0);
    }
    .shade-picker__shade {
      width: 39px;
      height: 39px;
    }
    .button {
      float: $ldirection;
    }
  }
  .product__promo,
  .product__full-details-link-wrapper {
    display: none;
  }
  .product--full .product__sku-categories {
    min-height: 0;
  }
  .product-collection-product div,
  .product-collection-product__wrap div {
    background-repeat: no-repeat;
  }
  .product_collection_hero {
    height: 361px;
    &__body {
      color: #ffffff;
      width: 380px;
      height: 160px;
      position: relative;
      top: 126px;
      #{$ldirection}: 103px;
    }
    h1 {
      font-family: Brandon Text Bold;
      font-size: 30px;
      line-height: 33px;
      letter-spacing: 9px;
      text-transform: uppercase;
      text-align: center;
      @include swap_direction(margin, 0 auto 18px auto);
    }
    &__subtitle {
      font-size: 20px;
      line-height: 33px;
      letter-spacing: 5px;
      text-align: center;
      @include swap_direction(margin, 0 auto 0 auto);
    }
    &__content {
      overflow: hidden;
    }
    &__content-item {
      float: $ldirection;
      width: 100%;
      position: relative;
    }
    &__content-item:nth-child(3) {
      width: 50%;
      min-height: 619px;
    }
    &__content-item:nth-child(4) {
      width: 50%;
      float: $rdirection;
      min-height: 619px;
    }
    &__content-item:nth-child(6) {
      width: 50%;
      min-height: 509px;
    }
    &__content-item:nth-child(7) {
      width: 50%;
      float: $rdirection;
      min-height: 509px;
    }
  }
  .product-collection-product {
    &__description {
      font-size: 16px;
      margin-bottom: 3px;
    }
    &__callout {
      font-family: Brandon Text Bold;
      font-size: 16px;
      color: #ff4661;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 6px;
    }
    h2,
    h3 {
      font-family: Brandon Text Bold;
      font-size: 24px;
      margin-bottom: 6px;
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 1em;
    }
    h3 {
      margin-top: 6px;
    }
    &__price {
      font-family: Brandon Text Bold;
      font-size: 16px;
    }
  }
  .button--outline {
    border: 1px solid #ff4661;
    color: #ff4661;
    margin-top: 18px;
  }
  .product__button {
    margin-top: 18px;
  }
  .product_eye-palette {
    width: 1024px;
    height: 371px;
    @include swap_direction(padding, 90px 30px 0 53%);
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    background-position: 30px 30px;
    .product-collection-product {
      &__callout {
      }
    }
  }
  .product_intense-pigment {
    font-size: 16px;
    height: 350px;
    @include swap_direction(padding, 40px 50% 0 30px);
    border-bottom: 1px solid #f3f3f3;
    background-position: 90% center;
  }
  .product-palette-atb {
    font-size: 16px;
    font-family: Brandon Text Bold;
    position: absolute;
    top: 215px;
    h3 {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 6px;
    }
    &.product_1 {
      #{$ldirection}: 30px;
    }
    &.product_2 {
      #{$ldirection}: 220px;
    }
    &.product_3 {
      #{$ldirection}: 410px;
    }
  }
  .product_nail-polish {
    height: 560px;
    background-position: center 30px;
    border-#{$rdirection}: 1px solid #f3f3f3;
    @include swap_direction(padding, 363px 30px 0);
  }
  .product_glitter-nail-polish {
    border-#{$rdirection}: 1px solid #f3f3f3;
    @include swap_direction(padding, 0 30px 30px);
    & h2 {
      display: none;
    }
  }
  .product_sheer-lip {
    height: 619px;
    background-position: center 53px;
    @include swap_direction(padding, 363px 30px 0);
  }
  .product_shadow-stick {
    height: 380px;
    background-position: center 30px;
    @include swap_direction(padding, 200px 30px 0);
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }
  .product_smokey-mascara {
    height: 569px;
    border-#{$rdirection}: 1px solid #f3f3f3;
    background-position: center 10px;
    @include swap_direction(padding, 280px 30px 0 50%);
    & .product-collection-product__price {
      width: 134px;
      margin-top: 15px;
    }
  }
  .product_brightening-brick {
    height: 539px;
    background-position: center 15px;
    @include swap_direction(padding, 380px 30px 0);
  }
  .product_brush {
    height: 300px;
    background-position: center 30px;
    @include swap_direction(padding, 100px 30px 0);
    border-top: 1px solid #f3f3f3;
  }
  .product-collection-footer {
    height: 240px;
    h3 {
      font-family: Brandon Text Bold;
      font-size: 22px;
      text-transform: uppercase;
      @include swap_direction(margin, 18px 0);
    }
    &__sub {
      font-size: 20px;
      line-height: 1.5em;
      margin-bottom: 18px;
    }
    &__link a {
      font-family: Brandon Text Bold;
      text-transform: uppercase;
      text-decoration: none;
      border-bottom: 1px solid;
      padding-bottom: 3px;
    }
  }
  .shop-all__button {
    @include swap_direction(margin, 27px 0);
  }
  .product-collection-footer_l,
  .product-collection-footer_r {
    height: 240px;
    width: 510px;
    @include swap_direction(margin, 40px 0);
    float: $ldirection;
    background-color: $color-gray-light-alt;
  }
  .product-collection-footer_l {
    padding-top: 30px;
    span {
      font-size: 3em;
      text-align: center;
    }
  }
  .product-collection-footer_r {
    margin-#{$ldirection}: 4px;
    padding-top: 37px;
  }
  .block-template-product-collection-prodcut-p-v1 {
    .content {
      .product-palette-atb {
        .product__button--add-to-bag {
          width: 80%;
        }
      }
    }
  }
}

.store-locator {
  .store-locator__tooltip {
    .store-locator__tooltip_map_info {
      float: $ldirection;
      width: 50%;
    }
  }
  .doors_results {
    .store-locator-section--list_item {
      .store-locator__tooltip {
        text-align: $ldirection;
      }
    }
  }
  .responsive-tabs--store-locator {
    ul.resp-tabs-list {
      li.resp-tab-item {
        &:first-child {
          &.resp-tab-active {
            @include swap_direction(border-width, 0 0px 0px 1px);
          }
        }
        &:last-child {
          @include swap_direction(border-width, 0 1px 1px 1px);
          &.resp-tab-active {
            @include swap_direction(border-width, 0 1px 0px 0px);
          }
        }
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .error-messages {
        color: #ff4661 !important;
      }
    }
  }
  .inner {
    .appt-book-page-header-content__subhead {
      p {
        font-size: 18px;
      }
    }
  }
  .appointment-select {
    .calendar-head {
      .cal-head-content {
        .cal-controls.previous {
          background: url(/media/export/cms/appointment_booking/appointments/cal_arrow_right.png) no-repeat transparent !important;
        }
        .cal-controls.next {
          background: url(/media/export/cms/appointment_booking/appointments/cal_arrow_left.png) no-repeat transparent !important;
        }
      }
    }
  }
  .book-appt-container {
    .registration__email-list,
    .registration__sms-list,
    .registration__terms {
      height: auto;
    }
    .registration__sms-list,
    .registration__terms {
      padding-bottom: 10px;
    }
    .appointment_privacy_legal_text {
      float: right;
      font-size: 12px;
    }
  }
  .appt-book-page-header-content {
    left: 0px;
    .appt-book-page-header-content__title {
      padding-right: 20px;
    }
    .appt-book-page-header-content__subhead {
      padding-right: 30px;
    }
  }
}

@media (max-width: 768px) {
  .appt-book-location-selector {
    .location-submit {
      @include swap_direction(margin, 30px auto);
    }
  }
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          .appt-book-mobile-prefix {
            font-size: 16px;
          }
          .appt-book-mobile {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-down) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 92px !important;
      width: 800px !important;
      margin-#{$ldirection}: -410px !important;
      padding-#{$rdirection}: 10px;
      .pika-single {
        .pika-lendar {
          border-left: none !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .selects-container {
          .artist-select-container {
            width: 100% !important;
            .artist-select {
              width: 63% !important;
            }
          }
          .counter-select-container {
            width: 60% !important;
          }
        }
        .confirm-container {
          width: 60% !important;
        }
      }
    }
    .location-select {
      background-position: 10px 50% !important;
    }
  }
}

.section-esearch {
  &.device-pc {
    #search-wrapper {
      #results-container {
        #col-2 {
          #product-results {
            .product-grid__item {
              width: 33.33%;
            }
          }
        }
      }
    }
    .esearch_results--pc {
      .product-thumb__image {
        height: 195px;
      }
    }
  }
}

.section-dark-circles-emergency-kit {
  .product-row-content {
    .product-row {
      .product-details {
        padding-#{$ldirection}: 0px;
        float: #{$ldirection};
        font-size: 16px;
      }
      .product-image {
        float: #{$ldirection};
      }
    }
    .plus-icon {
      float: #{$rdirection};
    }
  }
}

.section-k-beauty-day,
.section-k-beauty-night {
  .product_collection_hero__body {
    #{$rdirection}: 0px;
  }
}

.pro-how-to-block__tip1,
.pro-how-to-block__tip2 {
  #{$rdirection}: 0px;
}

.pro-how-to-block__single-tip {
  transform: translate(74%, 50%);
}

.makeup-lessons-landing {
  .makeup-lessons-item__lesson.is-video {
    .makeup-lessons-item__lesson-button {
      padding-#{$ldirection}: 10px;
      padding-#{$rdirection}: 31px;
    }
  }
}

#lpChat {
  .lp_radiobutton_wrapper {
    .lp_radio_button {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.product--full {
  .product-gallery__thumbs {
    .slick-list.draggable {
      padding-#{$ldirection}: 60px;
    }
    &.slick-initialized {
      .slick-slide {
        padding-#{$ldirection}: 0;
      }
    }
    button {
      &.slick-next {
        #{$ldirection}: 95%;
        &:before {
          #{$ldirection}: 0;
          transform: rotate(180deg);
        }
      }
      &.slick-prev {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
  .product__sku-categories-nav {
    border-left: 1px solid $color-black;
    border-right: none;
  }
}

.skin-foundation-collection {
  .product_collection_hero__content-item {
    .skin__foundation__dropdown__header,
    skin__foundation__dropdown__subheader,
    .video-carousel__block__header {
      font-family: inherit;
    }
    .discover-foundation-block__header,
    .pro-how-to-block__formatter {
      h2 {
        font-family: inherit;
      }
    }
  }
  .discover-foundation-container-two {
    .row:nth-child(1) {
      .discover-foundation-block {
        border-left: solid 0.5px #000000;
        border-right: none;
      }
    }
    .row {
      .discover-foundation-block {
        height: 556px;
      }
    }
  }
  .discover-foundation-container-one {
    .sub_asterisk_text {
      position: absolute;
      top: 10px;
      left: 19%;
    }
  }
  .pro-how-to-block__tip1,
  .pro-how-to-block__tip2 {
    right: 0;
  }
  .pro-how-to-block__single-tip {
    .pro-how-to-block__tip {
      right: 50%;
    }
  }
}

.section-apply-like-a-pro {
  .pro-how-to-block__content {
    .pro-how-to-block__tip1,
    .pro-how-to-block__tip2 {
      right: 0;
    }
    .pro-how-to-block__single-tip {
      .pro-how-to-block__tip {
        right: 50%;
      }
    }
  }
}

html {
  overflow-x: hidden;
}

.customer-service {
  .customer-service-section {
    #landing-email {
      background: url('/media/images/account/bg_account_landing.gif') no-repeat 0 100%;
      border-bottom: none;
      border-top: 1px solid $color-gray-alt;
    }
    #landing-faqs {
      border-right: none;
      border-top: 1px solid $color-gray-alt;
    }
    #landing-call {
      background: url('/media/images/account/bg_account_landing.gif') no-repeat 0px 100%;
      border-bottom: none;
    }
  }
}

.popup-offer-cbox {
  #cboxContent {
    .popup-offer__submit-wrap {
      .popup-offer__email-input {
        text-transform: lowercase;
      }
      h3.optional-info__header {
        border-top: 1px solid $color-black;
        border-bottom: none;
        margin: 15px 0 10px 0;
        padding: 16px 0 0px 0;
      }
    }
  }
}

.product-grid--carousel,
.video-carousel__block {
  .slick-prev {
    transform: rotate(180deg);
  }
  .slick-next {
    transform: rotate(180deg);
    right: auto;
  }
}

.skin__foundation__finder__container {
  margin: 28px 0 -13px 0;
}

.skin__foundation__slide__container {
  .slick-prev {
    top: 15%;
    &:before {
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: 95%;
    top: 15%;
    transform: rotate(180deg);
    &:before {
      right: 0;
    }
  }
}

.discover-foundation-container-two {
  .discover-foundation-block__header {
    h2 {
      margin: 40px 0 0;
      font-size: 40px;
    }
  }
}

.skin__foundation__models__container {
  .slick-list {
    width: 100%;
  }
  .skin__foundation__model__id {
    min-height: 300px;
  }
}

.detail_hero_retouching-pencil {
  .detail_hero__body {
    margin-right: 55%;
  }
  .product-collection-footer {
    display: flex;
  }
}

.section-crushed-lip-color {
  .product_collection_hero-v3 {
    .menu {
      li {
        @include swap_direction(margin, -1px);
        border-left: 1px solid #000000;
        &.last {
          display: none;
        }
      }
    }
  }
  .product_shop_all {
    .product-image-qs__description {
      #{$rdirection}: 0;
    }
  }
}

.quickshop {
  .quickshop__container {
    .rating_fav {
      .product__rating {
        display: none;
      }
    }
  }
}

.customer-service.customer-service-landing {
  .customer-service-section {
    #artist_chat {
      display: block;
      a.customer-service-landing-block__link {
        bottom: 0px;
      }
    }
  }
}

.extra_glow_collection,
.slww {
  .quickshop__container {
    .shaded_details {
      .shade-picker__color-name {
        .js-sku-menu-wrapper {
          .selectBox-label {
            &.js-sku {
              float: $ldirection;
              min-width: 0;
            }
          }
        }
      }
    }
  }
}

.skin-foundation-collection {
  &.ss18 {
    .row2 {
      .product-cta-qs__shop-details,
      .discover-foundation-block__data {
        width: 45%;
      }
    }
    h2,
    p,
    .header_hed,
    .header_dek,
    .text-block_title,
    .text-block_body {
      font-family: inherit !important;
    }
  }
}

.spp {
  .related_content_item {
    .homepage-slide {
      .slick-prev:before,
      .slick-next:before {
        transform: rotate(180deg);
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-single {
        .pika-lendar {
          border-left: none !important;
        }
      }
    }
  }
  .appt-book {
    .location-select {
      background-position: 10px 50% !important;
    }
  }
}

#gift-options-wrapper {
  #gift-wrap-img {
    display: none;
  }
}

.newsletter-info {
  .newsletter-info__header {
    text-indent: 0px;
    margin-top: 20px;
  }
}

.product {
  &__info {
    .product {
      &__unit-price {
        position: relative;
        top: 10px;
      }
    }
  }
  &__details {
    .shade-picker {
      &__color-name {
        .selectBox-dropdown {
          .selectBox-label {
            padding: 35px;
          }
        }
      }
    }
  }
}

.mpp-sort {
  .selectBox-menuShowing {
    .selectBox-arrow {
      #{$ldirection}: 10px;
    }
  }
  @include breakpoint($medium-down) {
    .mpp-sort-menu--arrow-down {
      display: block;
    }
    &-menu {
      &.selectBox {
        background: $color-white;
      }
    }
  }
}

.sticky-add-to-bag {
  .sticky-add-to-bag__inner {
    @include breakpoint($tablet-down) {
      padding-bottom: 40px;
    }
  }
}
