.profile-info {
  &__item.title_container {
    width: 100%;
  }
}

.account-profile,
.account-orders,
.account-address,
.account-payment,
.account-loyalty {
  text-align: center;
  .section-head {
    @include h13;
    @include swap_direction(padding, 0 40px);
  }
  .section-header {
    @include swap_direction(padding, 0 40px);
  }
  .section-content {
    text-align: $ldirection;
  }
  account-profile {
    &__name,
    &__email {
      word-wrap: break-word;
    }
  }
}

.order-details-page__content {
  .order-totals {
    width: 40%;
    table th {
      width: 80%;
    }
    table td {
      width: 40%;
      text-align: $rdirection;
    }
  }
}

.profile-pic-main {
  #profile_pic_overlay {
    #profile_pic_content {
      top: -122px;
    }
  }
}

.favorites-page {
  .favorites-page__content {
    .favorites-page__tabs {
      #{$rdirection}: 0px;
    }
    .favorites {
      &__recommended-products {
        .slick-track {
          float: $rdirection;
        }
      }
    }
    .my-lists__list-link--share:before {
      top: 24px;
    }
    .favorites__instruction-step {
      .favorites__instruction-step-title {
        line-height: 30px;
      }
      .favorites__recommended-products {
        .favorites__recommended-product {
          .description .product_name {
            min-height: 76px;
            a {
              border: none;
            }
          }
          @media only screen and (max-device-width: 768px) {
            .description .product_name {
              min-height: 105px;
            }
            .formatted_price {
              min-height: 100px;
              text-align: $ldirection;
              .swatch-container {
                margin-top: 10px;
                width: 100%;
              }
            }
            a.favorites__recommended-product-add-to-favorites {
              margin-#{$rdirection}: 0px;
            }
          }
          .favorites__recommended-product-actions {
            a.favorites__recommended-product-add-to-favorites {
              float: $ldirection;
            }
            a.favorites__recommended-product-add-to-cart {
              width: 82%;
              @include swap_direction(padding, 15px 0px 15px 0px);
            }
          }
        }
      }
    }
  }
}

.profile-pic-upload__form-wrapper {
  .upload-file__button button {
    font-size: 12px;
  }
  .upload-file__value {
    font-size: 12px;
    #{$ldirection}: inherit;
    #{$rdirection}: 0px;
  }
}

.password-sent-page .password-sent-page__content {
  .password-sent__text .sent-info__text {
    @include swap_direction(margin, 0 0 10px 20px);
  }
}

.password-reset-page .password-reset-page__content {
  .password-reset .password-reset__form {
    .password-reset__fieldset .password-reset__item input {
      display: block;
      margin-top: 10px;
    }
  }
}

.account__new-account .sign-in-page__indent {
  #registration_short .new-account__fieldset {
    input#form--registration_short--field--PASSWORD {
      margin-bottom: 20px;
    }
  }
}

.order-details-page .order-details-page__content {
  .order-products {
    .cart-item.sample {
      .cart-item__qty-label {
        display: block;
      }
    }
    .cart-item:last-child {
      border-bottom: 1px solid #bbbbb9;
    }
    .cart-header {
      font-weight: bold;
    }
    .cart-item {
      .cart-item__desc {
        width: 35%;
      }
      .cart-item__price {
        width: 19%;
      }
      .cart-item__total {
        text-align: $rdirection;
        float: $rdirection;
      }
      @media only screen and (max-device-width: 768px) {
        .cart-item__desc {
          width: 28%;
        }
        .cart-item__qty {
          width: 15%;
        }
      }
    }
  }
}

#colorbox {
  .create-wishlist__form #alter_list {
    .create-wishlist__header {
      display: block;
    }
    .create-wishlist__name {
      display: inline-block;
      width: 68%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .account-page .account-page__content {
    section {
      word-wrap: break-word;
      .section-header,
      .section-head {
        @include swap_direction(padding, 0px);
        display: block;
        margin-bottom: 20px;
        h3 {
          margin-bottom: 20px;
          border-bottom: 2px solid;
          padding-bottom: 5px;
        }
      }
      .section-header:before,
      .section-head:before {
        background: none;
      }
    }
  }
}

.order-confirmation {
  .order-details-page .order-details-page__content {
    .order-products .cart-item {
      .cart-item__desc {
        width: 25%;
      }
      .cart-item__price {
        width: 19%;
      }
      .cart-item__qty {
        width: 24%;
      }
      @media only screen and (max-device-width: 768px) {
        .cart-item__qty {
          width: 22%;
        }
      }
    }
  }
}

.past-purchases-page__content {
  width: auto;
  overflow: hidden;
  padding-bottom: 1px;
  .product-item {
    border-top: 1px #bbbbb9 solid;
    padding-top: 15px;
    margin-bottom: 15px;
  }
  .product-item:first-child {
    border-top: none !important;
  }
  .product-item__name a {
    font-family: 'Brandon Text Bold';
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
  }
  .product-item__swatch {
    display: inline-block;
  }
  .product-item__shade {
    display: inline-block;
    vertical-align: top;
    line-height: 21px;
  }
  .product-item__add-to-cart {
    @include swap_direction(margin, 20px 0px);
  }
}

.profile-page {
  &__content {
    .newsletter-info {
      .form-submit {
        float: $rdirection;
      }
    }
  }
}
