.appt-book {
  #appointment-book-sections {
    .appointment-booking-review__account-signup-form {
      clear: left;
      .signup__mobile-prefix {
        width: 50px !important;
      }
      .signup__mobile-phone {
        padding-right: 50px !important;
      }
      .signup__terms,
      .signup__email-list,
      .signup__sms-list {
        width: 100%;
      }
    }
    .selectBox-arrow {
      left: 0px;
      width: 38px;
    }
    .service-details {
      .service-title {
        font-size: 23px;
        height: auto !important;
      }
    }
    .pika-prev {
      float: right;
    }
    .pika-next {
      float: left;
    }
  }
  .my-appointments {
    #appt-book-no-appts-section {
      .appt-book-no-appts-content {
        .no-appts-content {
          left: 5%;
          right: auto !important;
        }
      }
    }
  }
}
